function validateEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

$(function(){
  // add CSRF token to header
  $.ajaxSetup({
    headers: { "X-CSRFToken": Cookies.get("csrftoken") }
  });

  $('form.email-only-subscribe input[type=email]').on('input', function() {
    var $this = $(this),
      $form = $this.parents('form'),
      email = $this.val(),
      $emailField = $form.find('input[type=email]');

    if(validateEmail(email)){
      $this.parents('form').find('.email-only-subscribe-trigger').removeAttr('disabled');
      $('.form-control-feedback').hide();
      $emailField.removeClass('is-invalid');
    } else {
      $this.parents('form').find('.email-only-subscribe-trigger').attr('disabled', true);
      $this.parents('form').find('.form-control-feedback').addClass('is-invalid').text('Please enter a valid email address').show().css('display', 'inline-block');
      $emailField.addClass('is-invalid');
    }
  });

  $('.email-only-subscribe-trigger').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();

    var $this = $(this),
      $form = $this.parents('form'),
      $nameField = $form.find('input[name=name]'),
      $emailField = $form.find('input[type=email]'),
      email = $emailField.val(),
      $checkboxFields = $('.custom-checkbox'),

      postData = {},
      formType = $this.data('form');
    
    var s708Input = $form.find('input[name="s708-input"]').is(":checked");

    if(!validateEmail(email)){
      $form.find('.form-control-feedback').addClass('is-invalid').text('Please enter a valid email address').show().css('display', 'inline-block');
      $emailField.addClass('is-invalid');
      return false;
    } else {
      if($emailField.hasClass('is-invalid')){
        $form.find('.form-control-feedback').removeClass('is-invalid').hide();
        $emailField.removeClass('is-invalid');
      }

      $('.email-only-subscribe-trigger').html('Saving...');
      $('.email-only-subscribe-trigger').attr('disabled', true);
    }

    postData.email = email;
    postData.name = email;
    postData.s708_input = s708Input;

    var subscribe_email_input = $form.find('input');
    var eventLabel ="Alerts Home-page";
    if(subscribe_email_input.attr('class') == "form-control-footer"){
      eventLabel = "Alerts Footer";
    }

    $.ajax({
      method: 'post',
      url: '/api/v1/subscribe/',
      data: postData
    }).done(function(result){
      // change subscribe forms
      $nameField.hide();
      $emailField.hide();
      $checkboxFields.hide();
      $this.hide();
      $form.find('.form-control-feedback').text('Thank you for subscribing!').show().css('display', 'inline-block');

      //ensure subscription popup doesnt show for someone who subscribes
      Cookies.set('subscription-popup-hide', true, { expires: 365 });
      $('form.email-only-subscribe').html('Thank you for subscribing!');
        
      var event = 'StocksDigital';
      if (window.location.href.indexOf("nextinvestors") > -1){
        event = 'nextInvestorsFormSubmit';
      }else if (window.location.href.indexOf("catalysthunter") > -1){
        event = 'chFormSubmit';
      }else if (window.location.href.indexOf("finfeed") > -1){
        event = 'ffFormSubmit';
      }else if (window.location.href.indexOf("wise-owl") > -1){
        event = 'woFormSubmit';
      }
      
      formType = $this.attr('data-form');
    
      if(dataLayer){
        data = {
          'event': event,
          'eventCategory': 'Form',
          'eventAction': 'Submit',
          'eventLabel': formType
        };
        window.dataLayer.push(data);
      }

    }).fail(function(result){
      //ensure subscription popup doesnt show for someone who subscribes
      Cookies.set('subscription-popup-hide', true, { expires: 365 });
      $('form.email-only-subscribe').html('Thank you for subscribing!');
    });

  });
});
